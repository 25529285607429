


export default function DebugPage () {

	return (
		<div className="min-h-screen bg-slate-100">
			<header className="px-4 py-4">
				<h2>DebugPage</h2>
			</header>
		</div>
	);
}
