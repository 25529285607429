import XintApp from './XintApp/XintApp';



function App () {
	return (
		<div className="App">
			<XintApp />
		</div>
	);
}

export default App;
