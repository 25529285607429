


export default function ThemeTab () {

	return (
		<section>
			<header>
				<h4 className="px-4 py-4">ThemeTab</h4>
			</header>
		</section>
	);
}
