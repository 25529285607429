


export default function PageTab () {

	return (
		<section>
			<header>
				<h4 className="px-4 py-4">PageTab</h4>
			</header>
		</section>
	);
}
