


export default function HelpTab () {
	return (
		<div className="min-h-screen bg-slate-100">
			<header className="max-w-5xl mx-auto px-4 py-4 min-h-screen">
				<h2>HelpTab</h2>
			</header>
		</div>
	);
}
